<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <form action="" @submit.prevent="onSubmit()">
                    <px-card>
                        <!-- <b-row>
                            <b-col>
                                <h6 class="text-primary">New Plan</h6>
                            </b-col>
                        </b-row><hr> -->
                            <b-row class="mb-2">
                                <b-col md="3" class="font-weight-bold">
                                    Plan Name
                                </b-col>
                                <b-col md="5">
                                    <b-form-input size="sm" type="text" name="name" v-model="input.plan_name" placeholder="name" required></b-form-input>
                                </b-col>
                            </b-row><hr>
                            <b-row class="mb-2">
                                <b-col md="3" class="font-weight-bold">
                                    Type
                                </b-col>
                                <b-col md="5">
                                    <b-form-select name="type" size="sm" v-model="input.type" :options="plan_type_opt" :disabled="edit_plan" required></b-form-select>
                                </b-col>
                            </b-row><hr>
                            <b-row class="mb-2">
                                <b-col md="3" class="font-weight-bold">
                                    Discount Type
                                </b-col>
                                <b-col md="5">
                                    <b-form-select name="type" size="sm" v-model="input.discountType" :options="discount_type_opt" required></b-form-select>
                                </b-col>
                            </b-row><hr>
                            <b-row class="mb-2">
                                <b-col md="3" class="font-weight-bold">
                                    Discount Value
                                </b-col>
                                <b-col md="5">
                                    <b-form-input size="sm" type="number" name="discountValue" v-model="input.discountValue" step="0.01" min="0" required></b-form-input>
                                </b-col>
                            </b-row><hr>
                            <div v-if="input.type != 1" >
                            <b-row class="mb-2">
                                <b-col md="3" class="font-weight-bold">
                                    Price
                                </b-col>
                                <b-col md="5">
                                    <b-form-input size="sm" type="number" name="price" v-model="input.price" :disabled="edit_plan" placeholder="price" required></b-form-input>
                                </b-col>
                            </b-row>
                            <hr>
                            </div>
                            <b-row>
                                <b-col md="3" class="font-weight-bold">
                                    Monitor Count
                                </b-col>
                                <b-col md="5">
                                    <b-form-input size="sm" type="number" name="monitorcount" v-model="input.monitorcount" required></b-form-input>
                                </b-col>
                            </b-row><hr>
                            <b-row>
                                <b-col md="3" class="font-weight-bold">
                                    Min. Interval Limit <br>
                                </b-col>
                                <b-col md="5">
                                    <b-form-input size="sm" type="number" name="minIntervalLimit" v-model="input.minIntervalLimit" required></b-form-input>
                                </b-col>
                            </b-row><hr>
                            <b-row>
                                <b-col md="3" class="font-weight-bold">
                                    Message Count
                                </b-col>
                                <b-col md="5">
                                    <b-form-input size="sm" type="number" name="messagecount" v-model="input.messagecount" required></b-form-input>
                                </b-col>
                            </b-row><hr>
                            <b-row>
                                <b-col md="3" class="font-weight-bold">
                                    Call Count
                                </b-col>
                                <b-col md="5">
                                    <b-form-input size="sm" type="number" name="callcount" v-model="input.callcount" required></b-form-input>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-row>
                                <b-col md="3" class="font-weight-bold">
                                    Subuser Count
                                </b-col>
                                <b-col md="5">
                                    <b-form-input size="sm" type="number" name="subuser limit" v-model="input.subUserLimit" required></b-form-input>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-row>
                                <b-col md="3" class="font-weight-bold">
                                Select Monitors Type
                                </b-col>
                            <b-col>
                                <multiselect variant="success"
                                :close-on-select="false"
                                v-model="input.monitorTypes" tag-placeholder="Select Monitor Type" placeholder="Search or add a Monitor Type" 
                                label="text" track-by="value" :options="monitor_type_opt" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                            </b-col>
                            </b-row>
                            <hr>
                            <b-row>
                                <b-col md="3" class="font-weight-bold">
                                Select Integrations
                                </b-col>
                            <b-col>
                                <multiselect variant="success"
                                :close-on-select="false"
                                v-model="input.alertsTypes" tag-placeholder="Select Alert Type" placeholder="Search or add a Alert Type" 
                                label="text" track-by="value" :options="alert_type_opt" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                            </b-col>
                            </b-row>
                            <hr>
                            <b-row class="mt-3 text-center">
                                <b-col>
                                    <b-button type="submit" variant="primary">{{edit_plan?'Edit Plan':'Add New Plan'}}</b-button>
                                </b-col>
                            </b-row>
                    </px-card>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
import ManagerController from "../../../services/managerController";
import Multiselect from 'vue-multiselect'
export default{
    name : "CreatePlan",
    components:{Multiselect},
    props:{create_plan_modal:Boolean,edit_plan:Boolean,plan_data:Object},
    data(){
        return {
            input:{type:null,monitorcount:50,minIntervalLimit:5,messagecount:0,callcount:0,subUserLimit:0, discountValue:0, discountType: null},
            plan_type_opt:[
                {value:null,text:'select plan type'},
                {value:'1',text:'Free'},
                {value:'2',text:'Paid'}
            ],
            discount_type_opt: [
                {value:null,text:'select discount type'},
                {value:'1',text:'Percentage'},
                {value:'2',text:'Fixed'}
            ],
            product:{},
            monitor_type_opt:[
              { value: null, text:'Please Select' },
              { value: 1, text:'HTTP(s)' },
              { value: 2, text:'Keyword' },
              { value: 3, text:'Ping' },
              { value: 4, text:'Port' },
              { value: 5, text:'Heartbeat' },
              { value: 6, text:'Page Speed (Paid)'},
              { value: 7, text:'Page Screenshot (Paid)'}
            ],
            alert_type_opt:[
              { value: null, text:'Please Select' },
              { value: 1, text:'Email' },
              { value: 2, text:'Skype' },
              { value: 3, text:'Telegram' },
              { value: 4, text:'Web push' },
              { value: 5, text:'Webhook' },
              { value: 6, text:'Message' },
              { value: 7, text:'Call' },
              { value: 8, text:'Slack' },
              { value: 9, text:'Zappier webhook' },
              { value: 10, text: 'Zulip' },
            ]
        }
    },
    computed:{
    },
    mounted() {
        this.getProduct();
        if (this.edit_plan) {
            this.input = { ...this.plan_data };

            this.input.monitorTypes = this.input.monitorTypes.map(mt =>
                this.monitor_type_opt.find(t => t.value === mt)
            );

            this.input.alertsTypes = this.input.alertsTypes.map(at =>
                this.alert_type_opt.find(t => t.value === at)
            );

            this.input.discountType = this.input.discountType === 'percentage' ? '1' : '2';
        }
    },
    methods:{
        async getProduct(){
            let response = await ManagerController.getProduct()
            if (response.result) {
                this.product = response.data[0];
            }
        },
       async onSubmit(){
        var monitorTypes = this.input.monitorTypes.map((l)=>{ return l.value})
        var alertsTypes = this.input.alertsTypes.map((m)=>{ return m.value})

        var discountType = this.input.discountType == '1' ? 'percentage' : 'fixed';
        
        var payload = {
            "planname": this.input.plan_name,
            "price": this.input.price,
            "monitorcount": this.input.monitorcount,
            "product_id": this.product.product_id,
            "minIntervalLimit": this.input.minIntervalLimit,
            "messagecount": this.input.messagecount,
            "callcount": this.input.callcount,
            "subUserLimit": this.input.subUserLimit,
            "monitorTypes": monitorTypes,
            "alertsTypes": alertsTypes,
            "type": this.input.type,
            "discountType": discountType,  // Adding discountType
            "discountValue": this.input.discountValue // Adding discountValue
        };

        if (this.input.type == '1') {
            payload = {
                "planname": this.input.plan_name,
                "type": this.input.type,
                "monitorcount": this.input.monitorcount,
                "minIntervalLimit": this.input.minIntervalLimit,
                "messagecount": this.input.messagecount,
                "callcount": this.input.callcount,
                "subUserLimit": this.input.subUserLimit,
                "monitorTypes": monitorTypes,
                "alertsTypes": alertsTypes,
                "discountType": discountType,  // Adding discountType
                "discountValue": this.input.discountValue // Adding discountValue
            };
        }

        if (payload) {
            var response;
            if (this.edit_plan) {
                var changepayload = {
                    "plan_name": this.input.plan_name,
                    "monitorcount": this.input.monitorcount,
                    "minIntervalLimit": this.input.minIntervalLimit,
                    "messagecount": this.input.messagecount,
                    "callcount": this.input.callcount,
                    "subUserLimit": this.input.subUserLimit,
                    "monitorTypes": monitorTypes,
                    "alertsTypes": alertsTypes,
                    "discountType": discountType,  // Adding discountType
                    "discountValue": this.input.discountValue // Adding discountValue
                };
                var p = { "planid": this.input._id, "changePayload": changepayload };
                response = await ManagerController.editPlan(p);
            } else {
                response = await ManagerController.createPlans(payload);
            }

            if (response.result) {
                this.$toasted.show(response.message, {
                    theme: 'outline',
                    position: "bottom-center",
                    type: 'success',
                    duration: 2000
                });
                this.$emit('planmodal');
            } else {
                this.$toasted.show(response.message, {
                    theme: 'outline',
                    position: "bottom-center",
                    type: 'error',
                    duration: 2000
                });
            }
        }
        },
        addTag (newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.options.push(tag)
            this.value.push(tag)
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>