<template>
    <div>
        <Breadcrumbs main="" title="Plans" />
        <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
              <b-button size="" variant="primary" class="btn-primary" @click="create_plan_modal = true" >Create New Plan +</b-button>
              <b-row class="mt-3">
                <b-col class="col-md-6 col-12">
                  <b-input-group>
                    <b-input-group-prepend >
                      <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                    </b-input-group-prepend>
                    <b-form-input
                      class="col-md-5"
                      v-model="filter"
                      placeholder="Type to Search"
                      type="search"
                    ></b-form-input>
                  </b-input-group>
                </b-col>


                <b-col class="text-right col-md-6 col-12">
                  <b-form-group
                    label-cols="10"
                    label="Per page"
                    class="mb-0"
                  >
                    <b-form-select
                      class="col-md-10"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="getPlans"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue text-left">
                <b-table
                  show-empty
                  stacked="md"
                  :items="items"
                  :filter="filter"
                  :fields="tablefields"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                <template #table-caption>Plans List.</template>

                <template #cell(plan_name)="data">
                  <b class="">{{ data.item.plan_name }}</b>
                </template>
                <template #cell(price)="data">
                  <b class="" v-if="data.item.price">${{ data.item.price }} /month</b>
                  <b class="" v-else>Free plan</b>
                </template>
                <template #cell(createdAt)="data">
                  <b class="">{{ changeDateFormate(data.item.createdAt) }}</b>
                </template>
                <template #cell(status)="data">
                  <div class="media-body icon-state switch-outline">
                      <label class="switch">
                      <input type="checkbox" :checked=data.item.status @change="statusUpdate(data.item)"><span class="switch-state" :class="data.item.status?'bg-success':'bg-danger'"></span>
                      </label>
                  </div>
                </template>
                <template #cell(action)="data">
                  <feather class="text-danger" type="trash-2" title="Delete" @click="deletePlan(data.item._id)"></feather>
                  <feather class="text-primary ml-2" type="edit" title="Edit" @click="editPlan(data.item)"></feather>                  
                </template>
                </b-table>
              </div>

              <b-col md="12" class="mt-3 text-right p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="getPlans('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="items.length >= 1" @click="getPlans('next')">Next &raquo;</a>
                </div>
              </b-col>
        </div>
      </div>
    </div>
    <b-modal id="modal-monitor" size="lg" title="Create New Plan" class="theme-modal" @hidden="closeModal()" v-model="create_plan_modal" no-close-on-backdrop hide-footer>
        <create-plan :create_plan_modal="create_plan_modal" @planmodal="closeModal" :edit_plan="edit_plan" :plan_data="plan_data"></create-plan>
        <template #modal-footer>
        <b-button @click="closeModal()" color="danger">Close</b-button>
        </template>
      </b-modal>
    </div>
</template>


<script>
import moment from "moment";
import ManagerController from "../../../services/managerController";
import CreatePlan from "./CreatePlan.vue"
export default {
    name: 'Plans',
    components:{CreatePlan},
    data(){
        return{
            plan_data:{},
            create_plan_modal:false,
            edit_plan: false,
            items:[],
            totalRows:0,
            filter:null,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 50],
            tablefields: [
                          { key: "plan_name", label: "Name", sortable: true },
                          { key: "status", label: "Status", sortable: true },
                          { key: "price", label: "Price", sortable: true },
                          { key: "discountType", label: "Discount Type", sortable: true },
                          { key: "discountValue", label: "Discount Value", sortable: true },
                          { key: "minIntervalLimit", label: "Min IntervalLimit", sortable: true },
                          { key: "messagecount", label: "Message Count", sortable: true },
                          { key: "callcount", label: "Call Count", sortable: true },
                          { key: "monitorcount", label: "MonitorCount", sortable: true },
                          { key: "createdAt", label: "CreatedAt", sortable: true },
                          { key: "action", label: "Action", sortable: false },
                        ],
            loading: false,
        }
    },
    mounted(){
      this.getPlans()
    },
    methods:{
        async getPlans(txt){
          this.loading = true;
          this.items = []
          if(txt == 'next'){this.currentPage ++}
          if(txt == 'prev'){
            if(this.currentPage != 1){
            this.currentPage --
            }
          }
          var paginationQuery = '?currentPage='+this.currentPage+'&pageSize='+this.perPage
          let response = await ManagerController.getPlans(paginationQuery)
          if (response.result) {
                this.items = response.data;
                this.totalRows = this.items.length;
            }
            this.loading = false;
        },
        async deletePlan(id){
          if(confirm('Are you sure to delete this Plan?')){
          this.loading = true;
          let response = await ManagerController.deletePlan("?planid="+id);
          if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
          }
          this.getPlans()
        }
        },
        editPlan(data){
          this.add_plan_modal = false
          this.create_plan_modal = true
          this.plan_data = data;
          this.edit_plan = true;
        },
        closeModal(){
          this.create_plan_modal = false
          this.edit_plan = false;
          this.getPlans();
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      changeDateFormate(d) {
      return moment(d).format("D/M/YYYY, HH A");
      },
      async statusUpdate(data){
        let status = true
        if(data.status == true){
            status = false
        }
        if (data.status == false) {
          data.status = true;
        } else {
          data.status = false;
        }
        var changepayload = {"status": status}
        var p = { "planid": data._id, "changePayload": changepayload}
        let response = await ManagerController.editPlan(p)
        if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            // this.getPlans()
        }
      },
    }
}

</script>